import { useClosedSidebar } from "../../shared/hooks/useClosedSdebar";
import { Grid, Input, makeStyles, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { set } from "lodash";
import { string } from "prop-types";
import { useEffect, useMemo, useState } from "react";


const useStyles = makeStyles({
  root: {
    "& input": {
      height: "30px",
    },
    "& th": {
      borderRight: "1px solid lightgray",
      backgroundColor: "#fddfdd",
      padding: "10px 0px",
      fontSize: "1.2em",
      textAlign: "center",
    },
    "& td": {
      borderRight: "1px solid lightgray",
      padding: "4px 0px",
      fontSize: "1.1em",
    },
    "& tr": {
      '&:nth-child(odd)': {
        backgroundColor: '#EAEAEA',
      },
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' // fourth bg color
      },
      '&:hover': {
        backgroundColor: '#d1dfd1 !important' // fifth bg color
      }
    },
    //alternative row only firs column
    '& tr:nth-child(even) td:first-child': {
      backgroundColor: '#FFFFFF',
    },
    '& tr:nth-child(odd) td:first-child': {
      backgroundColor: '#EAEAEA',
    },
  }
});


export const ShiftsMakerPage = (props: any) => {
  useClosedSidebar();
  const classes = useStyles();

  const tableHeight = 150;
  const cellWitdh = 150;

  const [employeesList, setEmployeesList] = useState<any>([
    { id: 1, label: "Mario Rossi" },
    { id: 2, label: "Giuseppe Verdi" },
    { id: 3, label: "Maria Bianchi" },
    { id: 4, label: "Giovanni Neri" },
    { id: 5, label: "Luca Gialli" },
    { id: 6, label: "Paolo Arancioni" },
    { id: 7, label: "Giorgio Viola" },
    { id: 8, label: "Giacomo Marroni" },
    { id: 9, label: "Giovanna Azzurri" },
    { id: 10, label: "Giulio Turchesi" },
    { id: 11, label: "Giovanni Neri" },
    { id: 12, label: "Luca Gialli" },
    { id: 13, label: "Paolo Arancioni" },
    { id: 14, label: "Giorgio Viola" },
    { id: 15, label: "Giacomo Marroni" },
    { id: 16, label: "Giovanna Azzurri" },
    { id: 17, label: "Giulio Turchesi" },
    { id: 18, label: "Giovanni Neri" },
    { id: 19, label: "Luca Gialli" },
    { id: 20, label: "Paolo Arancioni" },
    { id: 21, label: "Giorgio Viola" },
    { id: 22, label: "Giacomo Marroni" },
    { id: 23, label: "Giovanna Azzurri" },
    { id: 24, label: "Giulio Turchesi" },
    { id: 25, label: "Giovanni Neri" },
    { id: 26, label: "Luca Gialli" },
    { id: 27, label: "Paolo Arancioni" },
    { id: 28, label: "Giorgio Viola" },
    { id: 29, label: "Giacomo Marroni" },
    { id: 30, label: "Giovanna Azzurri" },
    { id: 31, label: "Giulio Turchesi" },
    { id: 32, label: "Giovanni Neri" },
    { id: 33, label: "Luca Gialli" },
  ]);

  const [tagsList, setTagsList] = useState<any>([
    { id: 1, label: "NA1" },
    { id: 2, label: "SA1" },
    { id: 3, label: "BE1" },
    { id: 4, label: "MAG1" },
    { id: 5, label: "UFF1" },
    { id: 6, label: "NA2" },
    { id: 7, label: "SA2" },
    { id: 8, label: "BE2" },
    { id: 9, label: "MAG2" },
    { id: 10, label: "UFF2" },
    { id: 11, label: "NA3" },
    { id: 12, label: "SA3" },
    { id: 13, label: "BE3" },
    { id: 14, label: "MAG3" },
    { id: 15, label: "UFF3" },
    { id: 16, label: "NA4" },
    { id: 17, label: "SA4" },
    { id: 18, label: "BE4" },
    { id: 19, label: "MAG4" },
    { id: 20, label: "UFF4" },
    { id: 21, label: "NA5" },
    { id: 22, label: "SA5" },
    { id: 23, label: "BE5" },
    { id: 24, label: "MAG5" },
    { id: 25, label: "UFF5" },
    { id: 26, label: "NA6" },
    { id: 27, label: "SA6" },
    { id: 28, label: "BE6" },
    { id: 29, label: "MAG6" },
    { id: 30, label: "UFF6" },
    { id: 31, label: "NA7" },
    { id: 32, label: "SA7" },
    { id: 33, label: "BE7" },
  ]);

  const columns: {
    [key: string]: number
  } = {
    "TAG": 120,
    "ORA": 70,
    "INFO": 150,
    "": 3
  }

  let hearerAlterateColor = "white";
  const [days, setDays] = useState<any>([new Date("2024-09-01"), new Date("2024-09-02"), new Date("2024-09-03"), new Date("2024-09-04"), new Date("2024-09-05"), new Date("2024-09-06"), new Date("2024-09-07"), new Date("2024-09-08"),]);
  const [shifts, setShifts] = useState<any>({});
  const [selectedShift, setSelectedShift] = useState<any>("")
  const [tagStatus, setTagStatus] = useState<any>({})
  const [modified, setModified] = useState<any>(0)

  function getDayString(day: Date) {
    return day.getDate() + "/" + (day.getMonth() + 1) + "/" + day.getFullYear();
  }


  useMemo(() => {
    let temp: { [key: string]: any } = {};
    for (let day of days) {
      let day_string = getDayString(day);
      temp[day_string] = {};
      for (let employee of employeesList) {
        temp[day_string][employee.id] = {
          "TAG": "",
          "ORA": "",
          "INFO": ""
        }
      }
    }
    setShifts(temp);
    setSelectedShift(getDayString(days[0]));
  }, [])


  useEffect(() => {
    let tempTagsStatus: {
      [key: number]: {
        count: number,
        label: string
      }
    } = {}
    if (shifts && selectedShift) {
      tagsList.map((tag: any) => {
        tempTagsStatus[tag.id] = {
          count: 0,
          label: tag.label
        }
      })

      Object.keys(shifts[selectedShift]).map((employee: any) => {
        let tag = shifts[selectedShift][employee]["TAG"]
        if (tag) {
          tempTagsStatus[tag]["count"] = tempTagsStatus[tag]["count"] + 1
        }
      })

    }
    setTagStatus(tempTagsStatus)

  }, [selectedShift, modified])

  return (
    <>
      <Grid container style={{
      }}>
        <Grid item xs={2}
          style={{

          }}
        ><Typography variant="h5">TAG USATI:</Typography></Grid>

        {
          tagStatus && Object.keys(tagStatus).map((tag) => {
            console.log("tag", tagStatus[tag])
            return <Grid item xs={1}
              style={{
                backgroundColor: tagStatus[tag]["count"] !== 1 ? "lightcoral" : "white",
                padding: "10px",
                margin: "5px",
                borderRadius: "5px",
                border: "1px solid black",
              }}
            >{tagStatus[tag]["label"]} ({tagStatus[tag]["count"]})</Grid>
          })
        }

      </Grid>
      <div>

        <div style={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          height: "calc(100vh - " + tableHeight + "px)",
          width: "100%",
        }}>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  position: "sticky",
                  left: 0,
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 1000,
                  minWidth: cellWitdh + "px"
                }}
                ></TableCell>
                {
                  days.map((day: any) => {
                    if (hearerAlterateColor == "white") {
                      hearerAlterateColor = "rgba(253, 223, 221, 1)";
                    }
                    else {
                      hearerAlterateColor = "white";
                    }
                    return Object.keys(columns).map((label: any) => {
                      let bgColor = getDayString(day) == selectedShift ? "rgba(245, 252, 0, 1)" : hearerAlterateColor;
                      return <TableCell style={{
                        position: "sticky",
                        top: 0,
                        borderRight: "0px solid black",
                        zIndex: 999,
                        minWidth: columns[label] + "px",
                        backgroundColor: bgColor,
                        cursor: "pointer"
                      }}
                        onClick={() => {
                          setSelectedShift(getDayString(day));
                        }}
                      >{
                          (label == "TAG") ? getDayString(day) : ""
                        }
                        {
                          (label == "INFO") ? day.toLocaleDateString("it-IT", { weekday: 'long' }).toUpperCase() : ""
                        }</TableCell>
                    }
                    )
                  })
                }

              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  position: "sticky",
                  left: 0,
                  top: 45,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 1000,
                  minWidth: cellWitdh + "px"
                }}
                >Operatore</TableCell>
                {
                  days.map((day: any) => {
                    if (hearerAlterateColor == "white") {
                      hearerAlterateColor = "rgba(253, 223, 221, 1)";
                    }
                    else {
                      hearerAlterateColor = "white";
                    }
                    let bgColor = getDayString(day) == selectedShift ? "rgba(245, 252, 0, 1)" : hearerAlterateColor
                    return Object.keys(columns).map((label: any) => {
                      return <TableCell style={{
                        position: "sticky",
                        top: 45,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 999,
                        minWidth: columns[label] + "px",
                        backgroundColor: bgColor
                      }}
                      >{label}</TableCell>
                    }
                    )
                  })
                }

              </TableRow>
            </TableHead>
            <TableBody>

              {employeesList.map((employee: any) => {
                return (
                  <TableRow>
                    <TableCell
                      style={{
                        paddingLeft: "5px",
                        position: "sticky",
                        left: 0,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 99

                      }}
                    >{employee.label} (Autista)</TableCell>
                    {days.map((day: any) => {
                      let bgColor = getDayString(day) == selectedShift ? "rgba(245, 252, 0, 0.4)" : ""
                      return Object.keys(columns).map((label: any) => {
                        if (label == "TAG") {
                          return <TableCell
                            style={{
                              minWidth: columns[label] + "px",
                              textAlign: "center",
                              padding: "10px",
                              backgroundColor: bgColor
                            }}
                          >
                            <Select
                              fullWidth
                              onChange={(e) => {
                                let temShifts = shifts;
                                temShifts[getDayString(day)][employee.id]["TAG"] = e.target.value
                                setShifts(temShifts)
                                setModified(modified + 1)
                              }}
                              defaultValue=""
                            >
                              <option value="">{""}</option>
                              {tagsList.map((tag: any) => {
                                return <option value={tag.id}>{tag.label}</option>
                              })}
                            </Select>
                          </TableCell>
                        }
                        if (label == "ORA") {
                          return <TableCell
                            style={{
                              minWidth: columns[label] + "px",
                              textAlign: "center",
                              padding: "10px",
                              backgroundColor: bgColor
                            }}>
                            <Input type="time" />
                          </TableCell>
                        }
                        if (label == "INFO") {
                          return <TableCell
                            style={{
                              minWidth: columns[label] + "px",
                              textAlign: "center",
                              padding: "10px",
                              backgroundColor: bgColor
                            }}>
                            <Select
                              fullWidth
                              defaultValue="">
                              <option value="">{""}</option>
                              <option value={"X"}>{"Da correggere"}</option>
                              <option value={"V"}>{"Da chiedere"}</option>
                            </Select>
                          </TableCell>
                        }
                        return <TableCell
                          style={{
                            minWidth: columns[label] + "px",
                            textAlign: "center",
                            backgroundColor: "red"
                          }}>

                        </TableCell>
                      }
                      )
                    }
                    )}
                  </TableRow>
                )
              }
              )}

            </TableBody>
          </Table>

        </div>

      </div >
    </>
  );
};