import "./App.css";
import jwtTokenAuthProvider, { getUser } from "./authProvider";
import { Admin, Resource } from "react-admin";
import drfProvider from "./dataProvider";
import { DeliveryList } from "./Distribution/Delivery/DeliveryList";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { url } from "./constants";
import { DeliveryEdit } from "./Distribution/Delivery/DeliveryEdit";
import { DeliveryCreate } from "./Distribution/Delivery/DeliveryCreate";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createBrowserHistory } from "history";
import { SubjectEdit } from "./Subjects/SubjectEdit";
import { SubjectCreate } from "./Subjects/SubjectCreate";
import "leaflet-draw/dist/leaflet.draw-src.css";
import { SubjectList } from "./Subjects/SubjectList";
import { ImportList } from "./Import/ImportList";
import { FileUploadList } from "./FileUpload/FileUploadList";
import { FileUploadCreate } from "./FileUpload/FileUploadCreate";
import { SubjectSolverList } from "./SubjectsSolver/SubjectsSolverList";
import { CustomLayout } from "./components/CustomLayout";
import { EmployeeList } from "./Employees/EmployeeList";
import { EmployeeCreate } from "./Employees/EmployeeCreate";
import { EmployeeEdit } from "./Employees/EmployeeEdit";
import { VehicleList } from "./Vehicles/VehicleList";
import { VehicleCreate } from "./Vehicles/VehicleCreate";
import { VehicleEdit } from "./Vehicles/VehicleEdit";
import { Route } from "react-router-dom";
import { RoutePlannerPage } from "./RoutePlanner";
import { RouteList } from "./Distribution/Route/RouteList";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import QueueIcon from "@material-ui/icons/Queue";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import BarChartIcon from '@material-ui/icons/BarChart';
import MapIcon from "@material-ui/icons/Map";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HomeIcon from '@material-ui/icons/Home';
import TimelineIcon from "@material-ui/icons/Timeline";
import PublishIcon from "@material-ui/icons/Publish";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import StoreIcon from "@material-ui/icons/Store";
import AllInbox from "@material-ui/icons/AllInbox";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import TocIcon from "@material-ui/icons/Toc";
import { SiteCreate } from "./Sites/SiteCreate";
import { SiteEdit } from "./Sites/SiteEdit";
import { SiteList } from "./Sites/SiteList";
import polyglotI18nProvider from "ra-i18n-polyglot";
import italianMessages from "ra-language-italian";
import { CachetPaymentList } from "./Distribution/Cachet/CachetPayment/CachetPaymentList";
import { CachetEdit } from "./Distribution/Cachet/CachetEdit";
import { NoteList } from "./Distribution/Note/NoteList";
import { NotAssignedNotes } from "./Distribution/Note/NotAssignedNotes";
import { RouteEdit } from "./Distribution/Route/RouteEdit";
import { NoteEdit } from "./Distribution/Note/NoteEdit";
import { NoteCreate } from "./Distribution/Note/NoteCreate";
import { ItemCreate } from "./Items/ItemCreate";
import { ItemEdit } from "./Items/ItemEdit";
import { ItemList } from "./Items/ItemList";
import ContactsIcon from '@material-ui/icons/Contacts';
import { TariffCreate } from "./Items/Tariff/TariffCreate";
import { TariffEdit } from "./Items/Tariff/TariffEdit";
import { TariffList } from "./Items/Tariff/TariffList";
import { CalculateChargesList } from "./Charges/CalculateChargesList/CalculateChargesList";
import { ChargeEdit } from "./Charges/ChargeEdit";
import it from "date-fns/locale/it";
import { VerifiedChargesList } from "./Charges/VerifiedChargesList/VerifiedChargesList";
import { InvoiceList } from "./Invoices/InvoiceList";
import { InvoiceEdit } from "./Invoices/InvoiceEdit/InvoiceEdit";
import { InvoiceShow } from "./Invoices/components/InvoiceShow";
import { BoardRoutes } from "./Warehouse/BoardRoutes/BoardRoutes";
import { CachetToCashInList } from "./Distribution/Cachet/CachetToCashInList";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MoreIcon from "@material-ui/icons/More";
import { RemittanceList } from "./Distribution/Remittance/RemittanceList";
import { RemittanceEdit } from "./Distribution/Remittance/RemittanceEdit";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { InvoiceTimeTableList } from "./Invoices/InvoiceTimeTableList";
import { DeliveryZoneList } from "./Distribution/DeliveryZone/DeliveryZoneList";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import AppsIcon from "@material-ui/icons/Apps";
import { InvoiceCreate } from "./Invoices/InvoiceCreate/InvoiceCreate";
import { LoadRouteList } from "./Warehouse/LoadRoute/LoadRouteList";
import { LoadRouteEdit } from "./Warehouse/LoadRoute/LoadRouteEdit";
import { LoadDeliveriesList } from "./Warehouse/LoadDelivery/LoadDeliveriesList";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { CustomerSubjectList } from "./CustomersPortal/CustomerSubject/CustomerSubjectList";
import { CustomerSubjectEdit } from "./CustomersPortal/CustomerSubject/CustomerSubjectEdit";
import { CustomerSubjectCreate } from "./CustomersPortal/CustomerSubject/CustomerSubjectCreate";
import { CustomerOrderList } from "./CustomersPortal/CustomerOrder/CustomerOrderList";
import { CustomerOrderEdit } from "./CustomersPortal/CustomerOrder/CustomerOrderEdit";
import { CustomerOrderCreate } from "./CustomersPortal/CustomerOrder/CustomerOrderCreate";
import { CustomerImportFileCreate } from "./CustomersPortal/CustomerImportFile/CustomerImportFileCreate";
import { CustomerImportFileList } from "./CustomersPortal/CustomerImportFile/CustomerImportFileList";
import { DownloadCustomerImportTemplate } from "./components/DownloadCustomerImportTemplate";
import { UserClientCreate } from "./CustomersPortal/UserClient/UserClientCreate";
import { UserClientList } from "./CustomersPortal/UserClient/UserClientList";
import { UserClientEdit } from "./CustomersPortal/UserClient/UserClientEdit";
import { ReasonStatusEdit } from "./Items/ReasonStatus/ReasonStatusEdit";
import { ReasonStatusList } from "./Items/ReasonStatus/ReasonStatusList";
import { ReasonStatusCreate } from "./Items/ReasonStatus/ReasonStatusCreate";
import { CalculatePurchaseChargesList } from "./Charges/CalculateChargesList/CalculatePurchaseChargesList";
import { VerifiedPurchaseChargesList } from "./Charges/VerifiedChargesList/VerifiedPurchaseChargesList";
import { PurchaseInvoiceList } from "./Invoices/PurchaseInvoiceList";
import { PurchaseInvoiceTimeTableList } from "./Invoices/PurchaseInvoiceTimeTableList";
import { PurchaseChargeEdit } from "./Charges/PurchaseChargeEdit";
import { FirstPage } from "./FirstPage";
import { PurchaseInvoiceCreate } from "./Invoices/InvoiceCreate/PurchaseInvoiceCreate";
import { XMLFileUploadList } from "./Invoices/XMLFileUpload/XMLFileUploadList";
import { XMLFileUploadCreate } from "./Invoices/XMLFileUpload/XMLFileUploadCreate";
import { ChartAccountsList } from "./ChartAccounts/ChartAccounts/ChartAccountsList";
import { ChartAccountsCreate } from "./ChartAccounts/ChartAccounts/ChartAccountsCreate";
import { ChartAccountsEdit } from "./ChartAccounts/ChartAccounts/ChartAccountsEdit";
import { ChartAccountsTagsEdit } from "./ChartAccounts/ChartAccountsTag/ChartAccountsTagsEdit";
import { ChartAccountsTagsCreate } from "./ChartAccounts/ChartAccountsTag/ChartAccountsTagsCreate";
import { ChartAccountsTagsList } from "./ChartAccounts/ChartAccountsTag/ChartAccountsTagsList";
import { InvoiceChartAccountsGroupedList } from "./ChartAccounts/InvoiceChartAccounts/InvoiceChartAccountsGroupedList";
import { RibaList } from "./Invoices/RibaList";
import { DownloadSupersonicaImportTemplate } from "./components/DownloadSupersonicaImportTemplate";
import { SplitSegmentList } from "./SplitSegments/SplitSegmentList";
import { CachetBoxList } from "./Distribution/Cachet/CachetBoxList";
import { ChargesSummaryList } from "./Charges/ChargeSummary/ChargesSummaryList";
import { VerifiedChargesSummaryList } from "./Charges/ChargeSummary/VerifiedChargesSummaryList";
import { ForkliftList } from "./Forklift/ForkliftList";
import { EmailList } from "./Emails/EmailList";
import { DeliveryZoneEdit } from "./Distribution/DeliveryZone/DeliveryZoneEdit";
import { CategoryCreate } from "./Items/CategoryCreate";
import { CategoryList } from "./Items/CategoryList";
import { CategoryEdit } from "./Items/CategoryEdit";
import { CustomerDeliveryList } from "./CustomersPortal/CustomerDelivery/CustomerDeliveryList";
import { CarrierDeliveryList } from "./CarriersPortal/CarrierDelivery/CarrierDeliveryList";
import { UserCarrierList } from "./CarriersPortal/UserCarrier/UserCarrierList";
import { UserCarrierCreate } from "./CarriersPortal/UserCarrier/UserCarrierCreate";
import { UserCarrierEdit } from "./CarriersPortal/UserCarrier/UserCarrierEdit";
import { CarrierCashBoxList } from "./CarriersPortal/CarrierCashBox/CarrierCashBoxList";
import { QrScanner } from "./QrScanner";
import { PaymentStatusEdit } from "./Invoices/components/PaymentStatusEdit";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { DeliveryMergerList } from "./BusinessIntelligence/DeliveryMerger/DeliveryMergerList";
import { RouteMonitorList } from "./Warehouse/RouteMonitor/RouteMonitorList";
import { PermissionInfoPage } from "./PermissionInfo";
import { DeliveryGroupList } from "./Distribution/DeliveryGroup/DeliveryGroupList";
import { DeliveryGroupEdit } from "./Distribution/DeliveryGroup/DeliveryGroupEdit";
import { ChartAccountsTreeList } from "./ChartAccounts/ChartAccountsTree/ChartAccountsTreeList";
import { ChartAccountsTreePage } from "./ChartAccounts/ChartAccountsTree";
import { PresenceZoneEdit } from "./HR/PresenceZone/PresenceZoneEdit";
import { PresenceZoneList } from "./HR/PresenceZone/PresenceZoneList";
import { PresenceList } from "./HR/Presence/PresenceList";
import { PresenceEdit } from "./HR/Presence/PresenceEdit";
import { PresenceCreate } from "./HR/Presence/PresenceCreate";
import { PresenceQrCreate } from "./HR/PresenceQr/PresenceQrCreate";
import { PresenceQrEdit } from "./HR/PresenceQr/PresenceQrEdit";
import { PresenceQrList } from "./HR/PresenceQr/PresenceQrList";
import { BadgeCreate } from "./HR/Badge/BadgeCreate";
import { BadgeEdit } from "./HR/Badge/BadgeEdit";
import { BadgeList } from "./HR/Badge/BadgeList";
import { PresenceCalendar } from "./HR/Presence/PresenceCalendar";
import { PresenceQrGeneratorPage } from "./HR/PresenceQr/PresenceQrGeneratorPage";
import { PresenceOnlyInList } from "./HR/Presence/PresenceOnlyInList";
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import CropFreeIcon from '@material-ui/icons/CropFree';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { ShiftsMakerPage } from "./HR/Shifts/ShiftsMakerPage";
import { PresenceDayList } from "./HR/Presence/PresenceDayList";

const i18nProvider = polyglotI18nProvider(
  () => ({
    ...italianMessages,
    ra: {
      ...italianMessages.ra,
      message: {
        ...italianMessages.ra.message,
        bulk_update_title:
          "Modifica %{name} |||| Modifica %{smart_count} %{name} oggetti",
        bulk_update_content:
          "Sei sicuro di voler modificare questo %{name}? |||| Sei sicuro di voler modificare questi %{smart_count}?",
        bulk_delete_title:
          'Cancella %{name} |||| Cancella %{smart_count} %{name}',
        delete_content: 'Sicuro di volerlo cancellare?',
        error:
          "Attenzione si è verificato un errore, se l'errore persiste contattare l'amministratore del sistema",
      },
      action: {
        ...italianMessages.ra.action,
        add_filter: 'Filtri',
        create_item: "Nuovo",
      },
    },
    resources: {
      charges: { name: "Addebito |||| Addebiti" },
    },
  }),
  "it",
  {
    allowMissing: true,
  }
);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const history = createBrowserHistory();

function App() {
  const authProvider = jwtTokenAuthProvider({
    obtainAuthTokenUrl: `${url}/token/`,
  });
  const dataProvider = drfProvider(url);
  const customRoutes = [
    <Route exact path="/notes/not-assigned" component={NotAssignedNotes} />,
  ];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <Admin
          customRoutes={customRoutes}
          layout={CustomLayout}
          history={history}
          theme={{
            typography: {
              fontFamily: `"Inter", "Public Sans", "Helvetica", "Arial", sans-serif`,

              fontWeightLight: 300,
              fontWeightRegular: 400,
              fontWeightMedium: 500,
            },
            palette: {
              primary: {
                main: "#f44336",
                light: "#f443362b",
                dark: "#ba000d",
                contrastText: "#000000",
              },
              secondary: {
                light: "#484848",
                main: "#212121",
                dark: "#000000",
                contrastText: "#ffffff",
              },

              type: "light" as "light",
            },
            shape: {
              borderRadius: 7,
            },
            sidebar: { width: 200 },
            overrides: {
              //@ts-ignore
              RaMenuItemLink: {
                active: {
                  borderRight: "3px solid #f44336",
                  color: "#f44336",
                  background: "#f443362b",
                },
                icon: { color: "inherit" },
              },

              MuiMenuItem: {
                root: {
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  fontWeight: 600,
                  fontSize: "0.875rem",
                },
              },
              RaSidebar: {
                root: {
                  height: "100vh",
                },
                fixed: {
                  height: "100vh",
                },
              },
              RaDatagrid: {
                headerCell: {
                  color: "#f44336",
                  backgroundColor: "#fde2e0",
                  height: "40px",
                  fontWeight: 550,
                  whiteSpace: "nowrap",
                  "&&:first-child": {
                    borderTopLeftRadius: "0px",
                  },
                  "&&:last-child": {
                    borderTopRightRadius: "0px",
                  },
                },
              },
              MuiTableCell: {
                sizeSmall: { padding: "0px 5px 0px 5px" },
                root: { fontSize: "0.85rem", whiteSpace: "nowrap" },
              },
              MuiPaper: { rounded: { borderRadius: "0px" } },

              MuiButton: {
                text: { textTransform: "none", fontWeight: 600 },
                contained: {
                  backgroundColor: "#fff",
                  color: "#4f3cc9",
                  boxShadow: "none",
                },
              },

              MuiButtonBase: {
                root: {
                  "&:hover:active::after": {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: "block",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "currentColor",
                    opacity: 0.3,
                    borderRadius: "inherit",
                  },
                },
              },
              MuiAppBar: {
                root: {
                  boxShadow: "none",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                },
                colorSecondary: { backgroundColor: "white", color: "primary" },
              },

              RaBulkActionsToolbar: {
                toolbar: {
                  minHeight: "20px",
                  height: "25px",
                  position: "fixed",
                  backgroundColor: "lightgreen",
                  left: "15%",
                  right: "5%",
                  top: "0px",
                  zIndex: 9999999,
                }
              },


              MuiLinearProgress: {
                colorPrimary: {
                  backgroundColor: "#f5f5f5",
                },
                barColorPrimary: {
                  backgroundColor: "#d7d7d7",
                },
              },
              MuiFilledInput: {
                root: {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  "&$disabled": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                },
              },
            },
            props: {
              MuiButtonBase: {
                // disable ripple for perf reasons
                disableRipple: true,
              },
            },
          }}
          authProvider={authProvider}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
        >
          {(permissions) => {
            return [
              //console.log(permissions),
              <Resource
                icon={HomeIcon}
                options={{ label: "Homepage" }}
                name="home"
                list={FirstPage} />,

              permissions.includes('distribution.change_note') ? (
                <Resource
                  icon={PhotoCameraIcon}
                  options={{ label: "WebScanner" }}
                  name="qr_scanner"
                  list={QrScanner} />) : null,
              /*
              permissions.includes('distribution.change_note') ? (
                <Resource
                  icon={PhotoCameraIcon}
                  options={{ label: "WebScanner2" }}
                  name="qr_scanner_2"
                  list={QrScanner2} />) : null,
              */

              // ----------------  REGISTRY  
              getUser().is_admin === true ? (
                <Resource
                  icon={BarChartIcon}
                  options={{ label: "BI", isMenuParent: true }}
                  name="business_intelligence" />) : null,
              getUser().is_admin === true ? (
                <Resource
                  icon={GroupWorkIcon}
                  name="delivery_mergers"
                  list={DeliveryMergerList}
                  options={{ label: "Analisi pesi/volumi", menuParent: "business_intelligence" }} />) : null,



              // ----------------  REGISTRY  
              permissions.includes('registry.view_site') ||
                permissions.includes('employees.view_employee') ||
                permissions.includes('fleet_management.view_vehicle') ||
                (permissions.includes('registry.view_subject') && permissions.includes('registry.change_subject')) ? (
                <Resource
                  icon={CollectionsBookmarkIcon}
                  options={{ label: "Anagrafiche", isMenuParent: true }}
                  name="registry" />) : null,
              permissions.includes('registry.view_site') ? (
                <Resource
                  icon={StoreIcon}
                  name="sites"
                  list={SiteList}
                  create={SiteCreate}
                  edit={SiteEdit}
                  options={{ label: "Sedi logistiche", menuParent: "registry" }} />) : null,
              permissions.includes('employees.view_employee') ? (
                permissions.includes('employees.change_employee') ? (
                  <Resource
                    icon={SupervisorAccountIcon}
                    name="employees"
                    list={EmployeeList}
                    create={EmployeeCreate}
                    edit={EmployeeEdit}
                    options={{ label: "Impiegati", menuParent: "registry" }} />) : (
                  <Resource
                    name="employees"
                    list={EmployeeList}
                    options={{ label: "Impiegati", menuParent: "registry" }} />)
              ) : null,


              permissions.includes('fleet_management.view_vehicle') ? (
                <Resource
                  icon={LocalShippingIcon}
                  name="vehicles"
                  list={VehicleList}
                  create={VehicleCreate}
                  edit={VehicleEdit}
                  options={{ label: "Veicoli", menuParent: "registry" }} />) : null,



              permissions.includes('registry.view_subject') && permissions.includes('registry.change_subject') ? (
                <Resource
                  name="subjects"
                  edit={SubjectEdit}
                  create={SubjectCreate}
                  list={SubjectList}
                  options={{ label: "Soggetti", menuParent: "registry" }}
                  icon={PermContactCalendarIcon} />) : (permissions.includes('registry.view_subject') ? (
                    <Resource
                      name="subjects" />) : null),


              // ----------------  HR  

              getUser().is_admin === true ? (<Resource
                options={{ label: "HR", isMenuParent: true }}
                name="hr"
                icon={VpnKeyIcon}
              />) : null,

              getUser().is_admin === true ? (<Resource
                options={{ label: "Configurazioni", isMenuParent: true, menuParent: "hr" }}
                name="hr_conf"
                icon={SettingsIcon}
              />) : null,

              getUser().is_admin === true ? (
                <Resource
                  name="badges"
                  options={{
                    label: "Badge", menuParent: "hr_conf",
                  }}
                  create={BadgeCreate}
                  edit={BadgeEdit}
                  list={BadgeList}
                  icon={ContactsIcon} />) : null,
              getUser().is_admin === true ? (
                <Resource
                  name="presence_qr"
                  options={{
                    label: "Qr accessi", menuParent: "hr_conf",
                  }}
                  create={PresenceQrCreate}
                  edit={PresenceQrEdit}
                  list={PresenceQrList}
                  icon={VpnKeyIcon} />) : null,

              getUser().is_admin === true ? (
                <Resource
                  name="presence_zones"
                  options={{
                    label: "Zone timbratura", menuParent: "hr_conf",
                  }}
                  edit={PresenceZoneEdit}
                  list={PresenceZoneList}
                  icon={EditLocationIcon} />) : null,

              getUser().is_admin === true ? (<Resource
                options={{ label: "Presenze", isMenuParent: true, menuParent: "hr" }}
                name="hr_presence"
                icon={VpnKeyIcon}
              />) : null,

              getUser().is_admin === true ? (
                <Resource
                  name="presences"
                  options={{
                    label: "Presenze", menuParent: "hr_presence",
                  }}
                  create={PresenceCreate}
                  edit={PresenceEdit}
                  list={PresenceList}
                  icon={AssignmentTurnedInIcon} />) : null,


              getUser().is_admin === true ? (
                <Resource
                  name="presences_day"
                  options={{
                    label: "Calendario sett. ", menuParent: "hr_presence",
                  }}
                  list={PresenceDayList}
                  icon={AssignmentTurnedInIcon} />) : null,

              getUser().is_admin === true ? (
                <Resource
                  name="presences_calendar"
                  options={{
                    label: "Calendario", menuParent: "hr_presence",
                  }}
                  list={PresenceCalendar}
                  icon={TuneIcon} />) : null,


              getUser().is_admin === true ? (
                <Resource
                  name="presence_qr_generator"
                  options={{
                    label: "Generatore Qr", menuParent: "hr",
                  }}
                  list={PresenceQrGeneratorPage}
                  icon={SelectAllIcon} />) : null,

              getUser().is_admin === true ? (
                <Resource
                  name="presences_only_in"
                  options={{
                    label: "Loggati", menuParent: "hr",
                  }}
                  list={PresenceOnlyInList}
                  icon={AssignmentTurnedInIcon} />) : null,

              getUser().is_admin === true && getUser().is_admin === false ? (
                <Resource
                  name="shifts_maker"
                  options={{
                    label: "Gestione Turni", menuParent: "hr",
                  }}
                  list={ShiftsMakerPage}
                  icon={TuneIcon} />) : null,
              // ----------------  IMPORT  
              permissions.includes('file_import.view_missingsubject') ||
                permissions.includes('file_import.view_importedfile') ||
                permissions.includes('file_import.change_importedfile') ? (
                <Resource
                  options={{ label: "Import", isMenuParent: true }}
                  name="import"
                  icon={SystemUpdateAltIcon} />) : null,
              permissions.includes('file_import.view_importedfile') ? (
                <Resource
                  options={{ label: "File importati", menuParent: "import" }}
                  name="imported-files"
                  list={ImportList}
                  icon={PlaylistAddCheckIcon} />) : null,
              permissions.includes('file_import.change_importedfile') ? (
                <Resource
                  options={{ label: "Carica file", menuParent: "import" }}
                  name="upload"
                  list={FileUploadList}
                  create={FileUploadCreate}
                  icon={PublishIcon} />) : null,
              permissions.includes('file_import.view_missingsubject') ? (
                <Resource
                  name="subjects-solver"
                  list={SubjectSolverList}
                  options={{
                    label: "Risolvi soggetti", menuParent: "import",
                  }}
                  icon={GroupAddIcon} />) : null,

              permissions.includes('file_import.view_importedfile') ? (
                <Resource
                  name="supersonica_template_import_files"
                  list={DownloadSupersonicaImportTemplate}
                  options={{ label: "Scarica template", menuParent: "import" }}
                  icon={InsertDriveFileIcon} />
              ) : null,

              // ----------------  DISTRIBUTION  
              permissions.includes('distribution.view_delivery') ||
                permissions.includes('distribution.view_stock') ||
                (permissions.includes('route_management.change_route') && permissions.includes('distribution.view_segment')) ||
                (permissions.includes('distribution.view_route') && permissions.includes('distribution.view_segment')) ||
                permissions.includes('distribution.view_deliveryzone') ||
                permissions.includes('distribution.view_note') ? (
                <Resource
                  options={{ label: "Distribuzione", isMenuParent: true }}
                  name="distribution"
                  icon={SystemUpdateAltIcon}
                />) : null,

              permissions.includes('distribution.view_delivery') ? (
                <Resource
                  name="deliveries"
                  list={DeliveryList}
                  edit={DeliveryEdit}
                  create={DeliveryCreate}
                  icon={QueueIcon}
                  options={{ label: "Missioni", menuParent: "distribution" }} />) : null,

              // permissions.includes('distribution.view_delivery') ? (
              //   <Resource
              //     name="deliveries_to_schedule"
              //     list={DeliveryToScheduleList}
              //     icon={QueueIcon}
              //     options={{ label: "Missioni ricorrenti", menuParent: "distribution" }}
              //   />) : null,
              /*
              permissions.includes('distribution.view_stock') ? (
                <Resource
                  name="stocks"
                  list={DeliveryStockedList}
                  icon={InboxIcon}
                  options={{ label: "Giacenze", menuParent: "distribution" }} />) : null,

              permissions.includes('distribution.view_stock') ? (
                <Resource
                  name="suspends"
                  list={DeliverySuspendedList}
                  icon={InboxIcon}
                  options={{ label: "Sospese", menuParent: "distribution" }} />) : null,
              */
              permissions.includes('route_management.change_route') && permissions.includes('distribution.change_delivery') ? (
                <Resource
                  name="planner"
                  list={RoutePlannerPage}
                  options={{ label: "Pianificatore", menuParent: "distribution" }}
                  icon={MapIcon} />) : null,

              permissions.includes('route_management.change_route') && permissions.includes('distribution.change_route') && permissions.includes('distribution.change_segment') ? (
                <Resource
                  name="routes"
                  list={RouteList}
                  edit={RouteEdit}
                  options={{ label: "Giri", menuParent: "distribution" }}
                  icon={TimelineIcon} />) : (permissions.includes('distribution.view_route') ? (
                    <Resource
                      name="routes"
                    />) : null),
              permissions.includes('distribution.view_splitsegment') ? (
                <Resource
                  name="split-segments"
                  list={SplitSegmentList}
                  options={{ label: "Split eseguiti", menuParent: "distribution" }}
                  icon={TimelineIcon} />) : null,

              permissions.includes('distribution.view_deliveryzone') ? (
                <Resource
                  name="delivery-zones"
                  options={{
                    label: "Zone", menuParent: "distribution",
                  }}
                  edit={DeliveryZoneEdit}
                  list={DeliveryZoneList}
                  icon={EditLocationIcon} />) : null,

              permissions.includes('distribution.view_note') ? (
                <Resource
                  name="notes"
                  list={NoteList}
                  edit={NoteEdit}
                  create={NoteCreate}
                  options={{ label: "Bolle", menuParent: "distribution" }}
                  icon={InsertDriveFileIcon} />) : null,

              permissions.includes('route_management.change_route') && permissions.includes('distribution.change_route') && permissions.includes('distribution.change_segment') ? (
                <Resource
                  name="delivery-groups"
                  list={DeliveryGroupList}
                  edit={DeliveryGroupEdit}
                  options={{ label: "Raggruppamenti", menuParent: "distribution" }}
                  icon={ListAltIcon} />) : null,


              permissions.includes('route_management.change_route') && permissions.includes('distribution.change_delivery') ? (
                <Resource name="autocomplete/delivery-group" />) : null,

              // ----------------  WAREHOUSE  
              permissions.includes('route_management.view_route') ||
                permissions.includes('distribution.view_segment') ? (
                <Resource
                  options={{ label: "Magazzino", isMenuParent: true }}
                  name="warehouse"
                  icon={HomeWorkIcon} />) : null,

              permissions.includes('route_management.view_route') ? (
                <Resource
                  name="board_routes"
                  list={BoardRoutes}
                  options={{ label: "Bacheca giri", menuParent: "warehouse" }}
                  icon={DeveloperBoardIcon} />) : null,

              permissions.includes('route_management.view_route') ? (
                <Resource
                  name="routes-monitor"
                  list={RouteMonitorList}
                  options={{ label: "Monitoraggio giri", menuParent: "warehouse" }}
                  icon={TimelineIcon} />) : null,

              permissions.includes('route_management.view_route') &&
                permissions.includes('distribution.view_route') &&
                permissions.includes('distribution.view_segment') ? (
                <Resource
                  name="load-routes"
                  list={LoadRouteList}
                  edit={LoadRouteEdit}
                  options={{ label: "Carica giri", menuParent: "warehouse" }}
                  icon={PlaylistAddCheckIcon} />) : null,

              permissions.includes('route_management.view_route') &&
                permissions.includes('distribution.view_segment') ? (
                <Resource
                  name="load-deliveries"
                  list={LoadDeliveriesList}
                  icon={AssignmentTurnedInIcon}
                  options={{ label: "Carica missioni", menuParent: "warehouse" }} />) : null,

              // ----------------  CACHET  
              permissions.includes('cash_on_delivery.display_cachet_to_cash_in') ||
                permissions.includes('cash_on_delivery.display_cachet_cashed') ||
                permissions.includes('cash_on_delivery.view_remittance') ||
                permissions.includes('cash_on_delivery.view_cachet_box') ? (
                <Resource
                  options={{ label: "Contrassegni", isMenuParent: true, }}
                  name="cachets-menu"
                  icon={ReceiptIcon} />) : null,

              permissions.includes('cash_on_delivery.display_cachet_to_cash_in') ? (
                <Resource
                  name="cachets"
                  edit={CachetEdit}
                  list={CachetToCashInList}
                  options={{ label: "Da incassare", menuParent: "cachets-menu", }}
                  icon={MoneyOffIcon} />) : null,

              permissions.includes('cash_on_delivery.display_cachet_to_cash_in') ?
                <Resource name="autocomplete/cachet-boxes" />
                : null,

              permissions.includes('cash_on_delivery.display_cachet_cashed') ? (
                <Resource
                  name="cachet-payments"
                  list={CachetPaymentList}
                  edit={CachetEdit}
                  options={{ label: "Incassi", menuParent: "cachets-menu", }}
                  icon={AttachMoneyIcon} />) : null,

              permissions.includes('cash_on_delivery.display_cachet_cashed') ? (
                <Resource
                  name="cachet-boxes"
                  list={CachetBoxList}
                  options={{ label: "Casse", menuParent: "cachets-menu", }}
                  icon={AllInbox} />) : null,

              permissions.includes('cash_on_delivery.view_remittance') ? (
                <Resource
                  name="remittances"
                  list={RemittanceList}
                  edit={RemittanceEdit}
                  options={{ label: "Rimesse", menuParent: "cachets-menu" }}
                  icon={TocIcon}
                />) : null,


              // ----------------  ARTICLES  
              permissions.includes('distribution.view_item') ||
                (permissions.includes('distribution.change_reasonstatus') && getUser().is_admin === true) ? (
                <Resource
                  options={{ label: "Articoli", isMenuParent: true }}
                  name="articles"
                  icon={AllInboxIcon} />) : null,

              permissions.includes('distribution.view_category') ? (
                <Resource
                  name="categories"
                  create={CategoryCreate}
                  list={CategoryList}
                  edit={CategoryEdit}
                  options={{ label: "Categorie articoli", menuParent: "articles", }}
                  icon={AllInboxIcon} />) : null,

              permissions.includes('distribution.view_item') ? (
                <Resource
                  name="items"
                  create={ItemCreate}
                  list={ItemList}
                  edit={ItemEdit}
                  options={{ label: "Articoli", menuParent: "articles", }}
                  icon={AllInboxIcon} />) : null,


              permissions.includes('distribution.view_tariff') ? (
                <Resource
                  name="tariffs"
                  create={TariffCreate}
                  edit={TariffEdit}
                  list={TariffList}
                  options={{ label: "Tariffe", menuParent: "articles", }}
                  icon={LocalAtmIcon} />) : null,
              /*
              permissions.includes('distribution.view_tariff') ? (
                <Resource
                  name="grouped_tariffs"
                  list={GroupedTariffList}
                  edit={TariffEdit}
                  options={{ label: "Tariffe/Clienti", menuParent: "articles", }}
                  icon={LocalAtmIcon} />) : null,
              */
              permissions.includes('distribution.change_reasonstatus') && getUser().is_admin === true ? (
                <Resource
                  name="reason_status"
                  create={ReasonStatusCreate}
                  edit={ReasonStatusEdit}
                  list={ReasonStatusList}
                  options={{ label: "Motivazioni stati", menuParent: "articles", }}
                  icon={QuestionAnswerIcon} />
              ) : (
                permissions.includes('distribution.view_reasonstatus') || permissions.includes('base.view_carrierdelivery') ? (
                  <Resource
                    name="reason_status"
                  />) : null
              ),

              // ----------------  ACCOUNTING 
              permissions.includes('accounting.change_charge') ||
                permissions.includes('accounting.change_invoice') ||
                permissions.includes('accounting.view_invoice') ||
                permissions.includes('accounting.view_invoicepaymentstatus') ? (
                <Resource
                  options={{ label: "Fatt. Vendita", isMenuParent: true }}
                  name="accounting"
                  icon={ReceiptIcon} />) : null,

              permissions.includes('accounting.change_charge') ? (
                <Resource
                  name="charges-summary"
                  list={ChargesSummaryList}
                  options={{ label: "Tot. valida righe", menuParent: "accounting" }}
                  icon={AccountTreeIcon} />) : null,

              permissions.includes('accounting.change_charge') ? (
                <Resource
                  name="charges"
                  list={CalculateChargesList}
                  edit={ChargeEdit}
                  options={{ label: "Valida righe", menuParent: "accounting" }}
                  icon={AccountTreeIcon} />) : null,



              permissions.includes('accounting.change_charge') ? (
                <Resource
                  name="verified-charges-summary"
                  list={VerifiedChargesSummaryList}
                  options={{ label: "Tot. prefatture", menuParent: "accounting" }}
                  icon={ConfirmationNumberIcon} />) : null,

              permissions.includes('accounting.change_charge') ||
                permissions.includes('accounting.change_invoice') ? (
                <Resource
                  name="verified-charges"
                  edit={ChargeEdit}
                  list={VerifiedChargesList}
                  options={{ label: "Prefattura", menuParent: "accounting" }}
                  icon={ConfirmationNumberIcon} />) : null,

              permissions.includes('accounting.view_invoice') ? (
                <Resource
                  name="invoices"
                  list={InvoiceList}
                  show={InvoiceShow}
                  edit={InvoiceEdit}
                  create={InvoiceCreate}
                  options={{ label: "Fatture", menuParent: "accounting" }}
                  icon={ReceiptIcon} />) : null,

              permissions.includes('accounting.view_invoice') ? (
                <Resource
                  name="ribas"
                  list={RibaList}
                  show={InvoiceShow}
                  edit={InvoiceEdit}
                  options={{ label: "Riba", menuParent: "accounting" }}
                  icon={ReceiptIcon} />) : null,


              permissions.includes('accounting.view_invoicepaymentstatus') ? (
                <Resource
                  name="invoices-time-table"
                  list={InvoiceTimeTableList}
                  options={{ label: "Scadenzario", menuParent: "accounting" }}
                  icon={DateRangeIcon} />) : null,

              permissions.includes('accounting.view_invoicepaymentstatus') ? (
                <Resource
                  name="invoice-payment-status"
                  edit={PaymentStatusEdit} />) : null,


              // ----------------  ACCOUNTING PURCHASE
              permissions.includes('accounting.change_charge') ||
                permissions.includes('accounting.change_invoice') ||
                permissions.includes('accounting.view_invoice') ||
                permissions.includes('accounting.view_invoicepaymentstatus') ? (
                <Resource
                  options={{ label: "Fatt. Acquisto", isMenuParent: true }}
                  name="accounting-a"
                  icon={ReceiptIcon} />) : null,


              permissions.includes('accounting.change_charge') ? (
                <Resource
                  name="charges-a"
                  list={CalculatePurchaseChargesList}
                  options={{ label: "Valida righe A.", menuParent: "accounting-a" }}
                  icon={AccountTreeIcon} />) : null,

              permissions.includes('accounting.change_charge') ||
                permissions.includes('accounting.change_invoice') ? (
                <Resource
                  name="verified-charges-a"
                  edit={PurchaseChargeEdit}
                  list={VerifiedPurchaseChargesList}
                  options={{ label: "Prefattura A.", menuParent: "accounting-a" }}
                  icon={ConfirmationNumberIcon} />) : null,

              permissions.includes('accounting.view_invoice') ? (
                <Resource
                  name="invoices-a"
                  list={PurchaseInvoiceList}
                  show={InvoiceShow}
                  edit={InvoiceEdit}
                  create={PurchaseInvoiceCreate}
                  options={{ label: "Fatture A.", menuParent: "accounting-a" }}
                  icon={ReceiptIcon} />) : null,

              permissions.includes('accounting.view_invoicepaymentstatus') ? (
                <Resource
                  name="invoices-time-table-a"
                  list={PurchaseInvoiceTimeTableList}
                  options={{ label: "Scadenzario A", menuParent: "accounting-a" }}
                  icon={DateRangeIcon} />) : null,

              permissions.includes('accounting.change_invoice') ? (
                <Resource
                  options={{ label: "XML", menuParent: "accounting-a" }}
                  name="xml_invoices_imports"
                  list={XMLFileUploadList}
                  create={XMLFileUploadCreate}
                  icon={PublishIcon} />) : null,

              // ----------------  ACCOUNTING CHART ACCOUNTS
              permissions.includes('accounting.change_chartaccounts') ||
                permissions.includes('accounting.view_chartaccounts') ||
                permissions.includes('accounting.change_chartaccountstag') ||
                permissions.includes('accounting.view_chartaccountstag') ? (
                <Resource
                  options={{ label: "Piano dei conti", isMenuParent: true }}
                  name="chartofaccounts"
                  icon={ReceiptIcon} />) : null,


              permissions.includes('accounting.view_invoicechartaccounts') ? (
                <Resource
                  name="invoicechartaccounts"
                  list={InvoiceChartAccountsGroupedList}
                  options={{ label: "Resoconto", menuParent: "chartofaccounts", }}
                  icon={AttachMoneyIcon} />) : null,


              permissions.includes('accounting.change_chartaccounts') ? (
                <Resource
                  name="chartaccounts"
                  list={ChartAccountsList}
                  create={ChartAccountsCreate}
                  edit={ChartAccountsEdit}
                  options={{ label: "Categorie Lista", menuParent: "chartofaccounts" }}
                  icon={AccountTreeIcon} />) : null,

              permissions.includes('accounting.change_chartaccounts') ? (
                <Resource
                  icon={AccountTreeIcon}
                  options={{ label: "Categorie Albero", menuParent: "chartofaccounts", }}
                  name="cartegories_tree"
                  list={ChartAccountsTreePage} />) : null,


              permissions.includes('accounting.change_chartaccountstag') ? (
                <Resource
                  name="chartaccountstags"
                  list={ChartAccountsTagsList}
                  create={ChartAccountsTagsCreate}
                  edit={ChartAccountsTagsEdit}
                  options={{ label: "Tags", menuParent: "chartofaccounts" }}
                  icon={MoreIcon} />) : null,

              // ----------------  CUSTOMERS PORTAL 
              permissions.includes('customers.view_customersubject') ||
                permissions.includes('base.view_deliverytracking') ||
                permissions.includes('customers.view_customerorder') ? (
                <Resource
                  options={{ label: "Portale Clienti", isMenuParent: true }}
                  name="customer_portal"
                  icon={AppsIcon} />) : null,

              permissions.includes('customers.view_customersubject') ? (
                <Resource
                  name="customer_subjects"
                  list={CustomerSubjectList}
                  edit={CustomerSubjectEdit}
                  create={CustomerSubjectCreate}
                  options={{ label: "Anagrafica", menuParent: "customer_portal" }}
                  icon={PermContactCalendarIcon} />) : null,

              permissions.includes('base.view_deliverytracking') ? (
                <Resource
                  name="customer_deliveries"
                  list={CustomerDeliveryList}
                  options={{ label: "Tracking", menuParent: "customer_portal" }}
                  icon={QueueIcon} />) : null,

              permissions.includes('customers.view_customerorder') ? (
                <Resource
                  name="customer_orders"
                  list={CustomerOrderList}
                  edit={CustomerOrderEdit}
                  create={CustomerOrderCreate}
                  options={{ label: "Ordini", menuParent: "customer_portal" }}
                  icon={QueueIcon} />) : null,

              permissions.includes('customers.view_customerorder') ? (
                <Resource
                  name="customer_import_files"
                  create={CustomerImportFileCreate}
                  list={CustomerImportFileList}
                  options={{ label: "Importa file", menuParent: "customer_portal" }}
                  icon={PublishIcon} />) : null,

              permissions.includes('customers.view_customerorder') ? (
                <Resource
                  name="customer_template_import_files"
                  list={DownloadCustomerImportTemplate}
                  options={{ label: "Scarica template", menuParent: "customer_portal" }}
                  icon={InsertDriveFileIcon} />
              ) : null,

              permissions.includes('auth.view_user') ? (
                <Resource
                  name="user_clients"
                  list={UserClientList}
                  create={UserClientCreate}
                  edit={UserClientEdit}
                  options={{ label: "Utenti", menuParent: "customer_portal" }}
                  icon={PermContactCalendarIcon} />) : null,

              // ----------------  CARRIER PORTAL 
              permissions.includes('base.view_carrierdelivery') ? (
                <Resource
                  options={{ label: "Portale Corrieri", isMenuParent: true }}
                  name="carrier_portal"
                  icon={AppsIcon} />) : null,

              permissions.includes('base.view_carrierdelivery') ? (
                <Resource
                  name="carrier_deliveries"
                  list={CarrierDeliveryList}
                  options={{ label: "Missioni", menuParent: "carrier_portal" }}
                  icon={QueueIcon} />) : null,

              permissions.includes('carriers.view_carriercashbox') ? (
                <Resource
                  name="carrier_cashboxes"
                  list={CarrierCashBoxList}
                  options={{ label: "Casse", menuParent: "carrier_portal" }}
                  icon={QueueIcon} />) : null,

              permissions.includes('auth.view_user') ? (
                <Resource
                  name="user_carriers"
                  list={UserCarrierList}
                  create={UserCarrierCreate}
                  edit={UserCarrierEdit}
                  options={{ label: "Utenti", menuParent: "carrier_portal" }}
                  icon={PermContactCalendarIcon} />) : null,


              // ----------------  FORKLIFT  
              getUser().is_admin === true ? (
                <Resource
                  options={{ label: "4.0", isMenuParent: true }}
                  name="interconnections"
                  icon={AllInboxIcon} />) : null,

              getUser().is_admin === true ? (
                <Resource
                  name="forklifts"
                  list={ForkliftList}
                  options={{ label: "Muletti", menuParent: "interconnections", }}
                  icon={AllInboxIcon} />) : null,

              getUser().is_admin === true ? (
                <Resource name="forklift" />
              ) : null,

              // ----------------  ADMIN  
              getUser().is_admin === true ? (
                <Resource
                  options={{ label: "Admin", isMenuParent: true }}
                  name="email-handlers"
                  icon={AllInboxIcon} />) : null,

              getUser().is_admin === true ? (
                <Resource
                  name="emails"
                  list={EmailList}
                  options={{ label: "Email", menuParent: "email-handlers", }}
                  icon={AllInboxIcon} />) : null,

              getUser().is_admin === true ? (
                <Resource
                  icon={HomeIcon}
                  options={{ label: "Info permessi", menuParent: "email-handlers", }}
                  name="permission_info"
                  list={PermissionInfoPage} />) : null,

              // -------- READ ONLY API
              // TODO: Ricordarsi di inserire dei permessi più robusti
              permissions.includes('distribution.view_deliveryattachment') ? (
                <Resource name="delivery_attachments" />) : null,
              <Resource name="delivery-items" />,
              <Resource name="delivery-note-rows" />,
              <Resource name="invoice-lines" />,
              <Resource name="clients" />,
              <Resource name="deliveries-plan" />,
              <Resource name="segments" />,
              <Resource name="payment-terms" />,
              //<Resource name="invoice-payment-status" />,
              <Resource name="delivery-states" />,
              <Resource name="provinces" />,
              <Resource name="sub-tariffs" />,
              <Resource name="notifications" />,


              <Resource name="autocomplete/user" />,
              <Resource name="autocomplete/delivery" />,
              <Resource name="autocomplete/main_delivery" />,
              <Resource name="autocomplete/subject" />,
              <Resource name="autocomplete/user" />,
              <Resource name="autocomplete/terms-payment" />,
              <Resource name="autocomplete/route" />,
              <Resource name="autocomplete/employee" />,
              <Resource name="autocomplete/vehicle" />,
              <Resource name="autocomplete/site" />,
              <Resource name="autocomplete/imported-files" />,
              <Resource name="autocomplete/delivery-zones" />,
              <Resource name="autocomplete/province" />,
              <Resource name="autocomplete/region" />,
              <Resource name="autocomplete/province-region" />,
              <Resource name="autocomplete/category" />,
              <Resource name="autocomplete/item" />,
              <Resource name="autocomplete/tag" />,
              <Resource name="autocomplete/department" />,
              <Resource name="autocomplete/invoice" />,
              <Resource name="autocomplete/bank" />,
              <Resource name="autocomplete/accountcompany" />,
              <Resource name="tariffs-client" />,
              <Resource name="customer_order_tariffs" />,
              <Resource name="customer_order_items" />,
              <Resource name="tariffs-clone-option" />,
              <Resource name="autocomplete/chartaccounts" />,

              <Resource name="autocomplete/user_clients" />,
              <Resource name="change_password" />,

              permissions.includes('base.view_carrierdelivery') ? (
                <Resource name="carrier_deliverystates" />) : null,


              // -------- READ ONLY API (CLIENTI)
              <Resource name="autocomplete/customer_subjects" />,
              <Resource name="customer_notes" />,


              //OTHER
              permissions.includes('distribution.change_route') ? (
                <Route path='/trackme' component={() => {
                  window.location.href = 'https://supersonica.be20tet.com';
                  return null;
                }} />) : null,

              (permissions.includes('distribution.change_note') || permissions.includes('base.view_carrierdelivery')) ? (
                <Route path='/ddt_scanner' component={() => {
                  window.location.href = 'https://www.dropbox.com/scl/fi/w87z7o8vcquht6kg6nue4/DDTScanner_Supersonica_v2.4.0.zip?rlkey=c746sqiq7ynzt4oi40kmwik0l&dl=0';
                  return null;
                }} />) : null,

            ];
          }}
        </Admin>
      </QueryClientProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
