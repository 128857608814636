import { Card, makeStyles } from "@material-ui/core";
import { ReactElement, ReactNode, useEffect } from "react";
import {
  ListProps,
  useCheckMinimumRequiredProps,
  useListController,
  ListContextProvider,
  useUnselectAll,
} from "react-admin";
import { clientNoJson } from "../dataProvider";
import { CustomListActions } from "./CustomListActions";
import { CustomListView } from "./CustomListView";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    transition: theme.transitions.create("margin-top"),
    position: "relative",
    flex: "1 1 auto",
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
    overflowX: "visible",
  },
}));

export const Container: React.FC = (props) => {
  const classes = useStyles();
  return <Card className={classes.root}>{props.children}</Card>;
};
export type CustomListProps = ListProps & {
  children: ReactElement;
  topElement?: ReactNode;
  additionalActions?: ReactNode;
  shouldExport?: boolean;
  euroExport?: boolean;
};
export const CustomList = (props: CustomListProps): ReactElement => {
  useCheckMinimumRequiredProps("List", ["children"], props);
  const {
    additionalActions,
    topElement: TopE,
    shouldExport,
    euroExport,
    ...listProps
  } = props;
  const controllerProps = useListController(listProps);
  const classes = useStyles();

  const urlParams = new URLSearchParams(window.location.search);
  const url_filter = urlParams.get('filter') ? new URLSearchParams(JSON.parse(urlParams.get('filter')!)).toString() : null;

  //Resect selected on filter change
  const unselectAll = useUnselectAll(controllerProps.resource);
  useEffect(() => {
    unselectAll();
  },
    [controllerProps.filterValues])


  return (
    <ListContextProvider value={controllerProps}>
      {props.topElement && props.topElement}
      <CustomListView
        {...controllerProps}
        classes={classes}
        {...listProps}
        actions={
          props.actions !== false &&
            props.actions ? props.actions :
            <CustomListActions
              additionalActions={additionalActions}
              shouldExport={shouldExport}
              euroExport={euroExport}
              exporter={
                () => {
                  clientNoJson(`api/${props.resource!}/export_excel/?${fixURL(url_filter)}${fixURLDefaultValues(props.filter)}`, {
                    method: "GET",
                    responseType: "arraybuffer",
                  }).then(
                    ({ data }) => {
                      const url = window.URL.createObjectURL(
                        new Blob([data], {
                          type: "application/ms-excel",
                        })
                      );
                      const actualFileName = `${props.resource!}.xlsx`

                      // uses the download attribute on a temporary anchor to trigger the browser
                      // download behavior. if you need wider compatibility, you can replace this
                      // part with a library such as filesaver.js
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", actualFileName);
                      document.body.appendChild(link);
                      link.click();
                      if (link.parentNode) link.parentNode.removeChild(link);

                    }

                  ).catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', { type: 'warning' });
                  })
                }
              }
              euroExporter={
                () => {
                  clientNoJson(`api/${props.resource!}/export_euro_excel/?${fixURL(url_filter)}`, {
                    method: "GET",
                    responseType: "arraybuffer",
                  }).then(
                    ({ data }) => {
                      const url = window.URL.createObjectURL(
                        new Blob([data], {
                          type: "application/force-download",
                        })
                      );
                      const actualFileName = `${props.resource!}.zip`

                      // uses the download attribute on a temporary anchor to trigger the browser
                      // download behavior. if you need wider compatibility, you can replace this
                      // part with a library such as filesaver.js
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", actualFileName);
                      document.body.appendChild(link);
                      link.click();
                      if (link.parentNode) link.parentNode.removeChild(link);

                    }

                  ).catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', { type: 'warning' });
                  })
                }
              }
            />

        }

      />
    </ListContextProvider>
  );
};
function notify(arg0: string, arg1: { type: string; }) {
  throw new Error("Function not implemented.");
}

// Function to fix all exports when multi-choice filters are enabled
function fixURL(url: string | null) {
  // Fix URL when multiple choice filters occur
  if (url != null) {
    // URL example to fix: root.com/?param1=ciao%2Ccomeva%2Ceccolo&param2=babb&param3=hhhko%2Cmokkhh
    if (url.indexOf("%2C") !== -1) {
      var fixedUrl = url;

      // Take the first parameter between ? and =
      var start_sequence = 0;
      var end_sequence = fixedUrl.indexOf("&");
      var sequence = fixedUrl.substring(start_sequence, end_sequence);
      if (sequence.indexOf("%2C") !== -1) {
        fixedUrl = fixedUrl.replace(sequence, fixParam(sequence));
      }

      var indexOfComma = fixedUrl.indexOf("%2C", end_sequence + 1);
      end_sequence = fixedUrl.length - end_sequence;

      while (indexOfComma !== -1) {
        start_sequence = fixedUrl.length - end_sequence + 1;
        end_sequence = fixedUrl.indexOf("&", start_sequence);

        if (end_sequence === -1)
          indexOfComma = -1;
        else {
          sequence = fixedUrl.substring(start_sequence, end_sequence);
          end_sequence = fixedUrl.length - end_sequence;
          fixedUrl = fixedUrl.replace(sequence, fixParam(sequence));
        }
      }

      return fixedUrl;

    }
    else
      return url;
  }
  else
    return url;
}

function fixParam(subUrl: string) {

  const end_param = subUrl.indexOf("=");
  const first_param = subUrl.substring(0, end_param);
  const fixed_subUrl = subUrl.replaceAll("%2C", "&" + first_param + "=");
  return fixed_subUrl;
}

function fixURLDefaultValues(filterValues: any) {
  let fixedUrl = "";
  console.log(filterValues)
  // Fix URL when multiple choice filters occur
  if (filterValues) {
    for (let [k, v] of Object.entries(filterValues)) {
      fixedUrl += "&" + k + "=" + v
    }
  }

  return fixedUrl;
}
