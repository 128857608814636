import { Card, CardContent, CardHeader, Grid, makeStyles, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { getUser } from "../../authProvider";


import { useEffect, useState, useCallback, useRef, useMemo } from "react";

import dataprovider, { client } from "../../dataProvider";
import { AutocompleteArrayInput, Button, DateTimeInput, ReferenceArrayField, ReferenceArrayInput, Tab, useNotify, useRedirect } from "react-admin";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
  root: {
    "& input": {
      height: "30px",
    },
    "& th": {
      borderRight: "1px solid lightgray",
      backgroundColor: "#fddfdd",
      padding: "10px 0px",
      fontSize: "1.2em",
      textAlign: "center",
    },
    "& td": {
      borderRight: "1px solid lightgray",
      padding: "4px 0px",
      fontSize: "1.1em",
    },
    "& tr": {
      '&:nth-child(odd)': {
        backgroundColor: '#EAEAEA',
      },
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' // fourth bg color
      },
      '&:hover': {
        backgroundColor: '#d1dfd1 !important' // fifth bg color
      }
    },
    //alternative row only firs column
    '& tr:nth-child(even) td:first-child': {
      backgroundColor: '#FFFFFF',
    },
    '& tr:nth-child(odd) td:first-child': {
      backgroundColor: '#EAEAEA',
    },
  }
});

export const PresenceCalendar = (props: any) => {
  const classes = useStyles();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlStartDate = urlParams.get('urlStartDate') ? urlParams.get('startDate') : new Date().toISOString().substring(0, 16);
  const urlEndDate = urlParams.get('endDate') ? urlParams.get('endDate') : new Date().toISOString().substring(0, 16);
  const urlEmployees = urlParams.get('employees') ? urlParams.get('employees')!.split(',').map(function (x) {
    return parseInt(x);
  }) : [];

  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [calendarData, setCalendarData] = useState<any>([]);
  const [calendarDays, setCalendarDays] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(urlStartDate);
  const [endDate, setEndDate] = useState<any>(urlEndDate);
  const [months, setMonths] = useState<any>([]);
  const [employees, setEmployees] = useState<any>(urlEmployees);
  const [employeesList, setEmployeesList] = useState<any>([]);
  const [tableHeight, setTableHeight] = useState<any>(150);

  const refSerarchBar = useRef(null)


  const hourMultiplier = 10;

  function loadEmpoyessList() {

    client(`/employees/get_all_employees`, {
      method: "GET",
      data: {
      }
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let employeesTemp = []
          for (let e in response) {
            employeesTemp.push({
              id: response[e].id,
              name: response[e].name,
              surname: response[e].surname,
              label: response[e].id + ": " + response[e].name + " " + response[e].surname
            })
          }
          setEmployeesList(employeesTemp)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }


  function reloadCalendar() {

    setLoading(true)
    client(`./presences/get_calendar/?start_date=${startDate}&end_date=${endDate}&employees=${employees}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let presence_empy = []
          for (let p_e in response.calendar) {
            presence_empy.push(response.calendar[p_e])
          }
          setCalendarData(presence_empy)
          setCalendarDays(response.days)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }

  useMemo(() => {
    reloadCalendar()
    loadEmpoyessList()
  }, [])


  useEffect(() => {
    if (refSerarchBar !== null && refSerarchBar.current !== null && refSerarchBar!.current!['clientHeight'] !== null)
      try {
        setTableHeight(refSerarchBar!.current!['clientHeight'] + 50)
      } catch (e) {
      }
  }, [
    refSerarchBar.current
  ])

  useEffect(() => {
    if (months.length > 0) {
      setStartDate(new Date(new Date(new Date(startDate).setDate(1)).setMonth(months[0] - 1)).toISOString().substring(0, 11) + "02:00")
      setEndDate(new Date(new Date(new Date(endDate).setDate(1)).setMonth(months[months.length - 1])).toISOString().substring(0, 11) + "02:00")
    }

  }, [months])

  return (
    <>
      <div style={{
        display: (loading) ? "block" : "none",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 999,
      }}>
        <Typography style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "2em",
        }}>
          Loading...
        </Typography>
      </div>
      <div onKeyDown={(e) => {
        if (e.key === "Enter") {
          reloadCalendar()
        }
      }}>
        {employeesList && employees &&
          <Grid container
            ref={refSerarchBar}
            style={{
              padding: "10px",
              marginRight: "30px",
              width: "100%"
            }}>
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}

              id="start_date"
              label="Da"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
            />
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}
              id="end_date"
              label="A"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              multiple
              id="months"
              options={[
                { id: 1, label: 'Gennaio' },
                { id: 2, label: 'Febbraio' },
                { id: 3, label: 'Marzo' },
                { id: 4, label: 'Aprile' },
                { id: 5, label: 'Maggio' },
                { id: 6, label: 'Giugno' },
                { id: 7, label: 'Luglio' },
                { id: 8, label: 'Agosto' },
                { id: 9, label: 'Settembre' },
                { id: 10, label: 'Ottobre' },
                { id: 11, label: 'Novembre' },
                { id: 12, label: 'Dicembre' },
              ]}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let tempMonths = []
                for (let e in newValue) {
                  tempMonths.push(newValue[e].id)
                }
                tempMonths.sort(function (a, b) { return a - b })
                setMonths(tempMonths)
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona mesi"
                />
              )}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              multiple
              id="tags-outlined"
              options={employeesList}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let emp = []
                for (let e in newValue) {
                  emp.push(newValue[e].id)
                }
                setEmployees(emp)
              }}
              value={employeesList.filter((option: any) => employees.indexOf(option.id) > -1)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona dipendenti"
                />
              )}
            />
            <Button
              style={
                {
                  height: "55px",
                  width: "100px",
                  marginRight: "10px"
                }
              }
              onClick={() => {
                reloadCalendar()
              }}
              label=""
              color="primary"
              variant="contained"
            >
              <Typography>Filtra</Typography>
            </Button>
          </Grid>
        }

        <div style={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          height: "calc(100vh - " + tableHeight + "px)",
          width: "100%",
        }}>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  position: "sticky",
                  left: 0,
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 999
                }}
                >Operatore</TableCell>
                {
                  calendarDays.map((day: any) => {
                    return (
                      <TableCell style={{
                        minWidth: 24 * hourMultiplier + "px",
                        maxWidth: 24 * hourMultiplier + "px",
                        position: "sticky",
                        top: 0,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 99
                      }}
                      >{day}</TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                calendarData &&
                calendarData.map((item: any) => {
                  return (
                    <TableRow>
                      <TableCell style={{
                        paddingLeft: "5px",
                        position: "sticky",
                        left: 0,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 99

                      }}>{item.id}: {item.name} {item.surname}</TableCell>
                      {
                        calendarDays.map((day: any) => {
                          return (
                            <TableCell>
                              <div style={{
                                display: "flex", flexDirection: "row",
                                width: 24 * hourMultiplier + "px !important",
                                minWidth: 24 * hourMultiplier + "px",
                                maxWidth: 24 * hourMultiplier + "px"
                              }}>
                                {
                                  item.presences[day] ?
                                    item.presences[day].map((presence: any) => {
                                      let color = "#f5a623"
                                      if (presence.hours > 9) {
                                        color = "rgb(249, 89, 89)"
                                      }
                                      else if (presence.hours > 6) {
                                        color = "#69b469"
                                      }
                                      else {
                                        color = "#f5a623"
                                      }

                                      return (
                                        <div
                                          style={{
                                            position: "relative",
                                            left: (parseInt(presence.date_in.substring(11, 13)) + (presence.date_in.substring(14, 16) / 60)) * hourMultiplier + "px",
                                            backgroundColor: color,
                                            width: (presence.hours * hourMultiplier).toString() + "px",
                                            height: "34px",
                                            borderRadius: "5px",
                                          }}
                                          title={presence.date_in + "/" + presence.date_out}
                                          onMouseEnter={(e) => {
                                            e.currentTarget.style.border = "3px solid purple"
                                            e.currentTarget.style.cursor = "pointer"
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.style.border = "0px"
                                            e.currentTarget.style.cursor = "default"
                                          }}
                                          onClick={(e) => {
                                            window.open("/presences/??displayedFilters={\"id\"%3Atrue}&filter={\"id\"%3A" + presence.id + "}", "_blank")
                                          }}
                                        >
                                          <div style={{
                                            paddingLeft: "5px",
                                            float: "left",
                                          }}>{
                                              parseInt(presence.date_in.substring(11, 13))
                                            }
                                          </div>
                                          <div style={{
                                            paddingRight: "5px",
                                            float: "right",
                                          }}>{
                                              presence.date_out && presence.hours > 4 ? parseInt(presence.date_out.substring(11, 13)) : null
                                            }
                                          </div>
                                        </div>
                                      )
                                    }) : null

                                }
                              </div>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          {calendarData &&
            calendarData.map((item: any) => {
              return (
                <div>
                  <Typography variant="h4">{item.employee}</Typography>
                  <Typography variant="h4">{item.date_in}</Typography>
                  <Typography variant="h4">{item.date_out}</Typography>
                </div>
              )
            })
          }
        </div>
      </div >
    </>
  );
};
