import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { getUser } from "../authProvider";


import { useEffect, useState, useMemo } from "react";

import { client } from "../dataProvider";
import { useNotify } from "react-admin";

import { useClosedSidebar } from "../shared/hooks/useClosedSdebar";


export const PresenceQrGenerator = (props: any) => {

  const compact = props.compact ? props.compact : false;
  const show_info = props.show_info ? props.show_info : false;

  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  useClosedSidebar();
  const [qrCodeBase64, setQrCodeBase64] = useState<string | null>(null);
  const [qrCodeValidity, setQrCodeValidity] = useState<any>(null);
  const [qrCode, setQrCode] = useState<any>(null);

  const user = getUser();

  var counter = 0
  let limitTime = 24 * 60 * 60 * 1000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter < limitTime && (["admin"].includes(user.username) || user.username.toLowerCase().includes("bacheca"))) {
        reloadQr();
        counter = counter + 125000
      }

    }, 125000);

    return () => clearInterval(timer);
  }, []);




  function reloadQr() {

    setLoading(true)
    client(`presence_qr/get_last_qr`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          setLoading(false)
          setQrCodeBase64(response.presence_qr.qr_code)
          setQrCodeValidity(response.presence_qr.valid_date)
          setQrCode(response.presence_qr.code)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }

  useMemo(() => {
    reloadQr()
  }, [])


  return (
    <Card
      style={{
        padding: compact ? 0 : 16,
        margin: compact ? 0 : 16,
      }}
    >
      <CardHeader style={{
        textAlign: "center",
        padding: compact ? 0 : 16,
        margin: compact ? 0 : 16,
      }} title={show_info === true ? "Qr login" : null} />
      <CardContent
        style={{
          padding: compact ? 0 : 16,
          margin: compact ? 0 : 16,
        }}>
        <Grid container spacing={2}>
          {loading ? (
            <Grid item xs={12} style={{
              textAlign: "center"
            }}>
              <Typography variant="h6" style={{
                textAlign: "center"
              }}>
                Caricamento in corso...
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} style={{
              textAlign: "center"
            }}>
              {show_info ? (
                <Typography variant="h6" style={{
                  textAlign: "center"
                }}>
                  {qrCode}
                </Typography>)
                : null}
              {
                qrCodeBase64 ? (
                  <img
                    style={{
                      padding: compact ? 0 : 4,
                      margin: compact ? -8 : 4,
                    }}
                    src={`data:image/jpeg;base64,${qrCodeBase64}`}
                  />) : null
              }
              {show_info ? (
                <Typography variant="h6" style={{
                  textAlign: "center"
                }}>
                  Valido fino a: <br />{new Date(`${qrCodeValidity}`).toLocaleString()}
                </Typography>) : null
              }

            </Grid>)}
        </Grid>
      </CardContent>
    </Card>
  );
};